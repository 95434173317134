angular.module('aerosApp')
    .constant("fiberTypes", [
        {id: "SMF", name: "All SMF", type: "Singlemode", alias: true},
        {id: "OS1", name: "OS1/OS1a", type: "Singlemode", alias: false},
        {id: "OS2", name: "OS2", type: "Singlemode", alias: false},
        {id: "MMF", name: "All MMF", type: "Multimode", alias: true},
        {id: "OM1", name: "OM1", type: "Multimode", alias: false},
        {id: "OM2", name: "OM2", type: "Multimode", alias: false},
        {id: "OM3", name: "OM3", type: "Multimode", alias: false},
        {id: "OM4", name: "OM4", type: "Multimode", alias: false},
        {id: "OM5", name: "OM5", type: "Multimode", alias: false}
    ]);